type AssetObject = {
    src: string;
}

export type Asset = string|AssetObject;

/**
 * TODO
 * @category Utils
 */
export function getAssetSrc(asset: any): string|undefined {
    if (typeof asset === 'string') {
        return asset;
    }

    if (typeof asset === 'object' && typeof asset.src === 'string') {
        return asset.src;
    }

    return undefined;
}