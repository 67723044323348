import React from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '../../utils/classNameUtils';

import styles from './loader.module.scss';

export type LoaderSize = 'small' | 'normal' | 'large' | 'xlarge';

export type SpinnerType = 'two-sides-circle' | 'three-sides-circle'

export interface IProps {
    size?: LoaderSize;
    spinnerType?: SpinnerType;
    inverted?: boolean;
    className?: string;
    grey?: boolean;
}

const Loader: React.FC<IProps> = props => {

    const {
        size,
        spinnerType = 'two-sides-circle',
        inverted,
        className,
        grey,
    } = props;

    const classNames = mergeClassNames([
        buildClassNames(styles, [
            `loader-${spinnerType}`,
            `loader-${spinnerType}--${size}`,
            (inverted && !grey) && `loader-${spinnerType}--inverted`,
            grey && `loader-${spinnerType}--grey`,
        ]),
        className,
    ]);

    return <div className={classNames} />;
};

export default React.memo(Loader);
