import React, {
    PropsWithChildren,
    useCallback,
} from 'react';
import { mergeClassNames } from '@ui/utils/classNameUtils';
import styles from './placeholderWrapper.module.scss';
import Button from '../../ui/inputs/Button/Button';

export interface IProps extends PropsWithChildren {
    image?: string;
    startPageLocation?: string;
    needToRefresh?: boolean;
    onHomeClicked?: () => void;
    /**
     * Скрытие кнопок: "Назад", "На главную"
     */
    hideButtons?: boolean;
}

const PlaceholderWrapper: React.FC<IProps> = props => {
    const didHomeClicked = useCallback(() => {
        if (props.needToRefresh || !props.onHomeClicked) {
            window.history.pushState({}, '', props.startPageLocation || '/');

            setTimeout(() => {
                document.location.reload();
            });
        } else {
            props.onHomeClicked();
        }
    }, [ props.startPageLocation, props.needToRefresh, props.onHomeClicked ]);

    const didBackClicked = useCallback(() => {
        // When we can not navigate back, just navigate to home page
        if (window.history.length > 0) {
            window.history.back();

            if (props.needToRefresh) {
                setTimeout(() => {
                    document.location.reload();
                });
            }
        } else {
            didHomeClicked();
        }
    }, [ props.needToRefresh, didHomeClicked ]);

    return (
        <div className={mergeClassNames([ styles['container'], 'h-ta-center' ])}>
            {props.image &&
                <img className={styles['image']} src={props.image} alt="" />
            }

            {props.children}

            {!props.hideButtons && (
                <div className={styles['buttons-list']}>
                    <Button className={styles['button']} noSpacing onClick={didBackClicked} text="Назад" outline />
                    <Button className={styles['button']} noSpacing onClick={didHomeClicked} text="На главную" />
                </div>
            )}
        </div>
    );
};

export default PlaceholderWrapper;
