import {
    BaseSyntheticEvent,
    MouseEvent,
} from 'react';

/**
 * TODO
 * @category Utils
 */
export const stopPropagation = <T>(e: MouseEvent<T>): void => e.stopPropagation();

/**
 * TODO
 * @category Utils
 */
export function wrapWithStopPropagation<T extends CallableFunction>(callback: T|undefined) {
    return (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        callback && callback();
    };
}